<template>
  <b-card>
    <b-row>
      <b-col>
        <h4>Registrando cliente</h4>
      </b-col>
      <b-col class="text-right">
        <b-button
          :to="{ name: 'waiting-list'}"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
        >
          <feather-icon icon="ArrowLeftIcon" style="color: white" />
        </b-button>
      </b-col>
    </b-row>

    <div v-if="!!guest">
      <b-overlay :show="isLoadingInitialData || isSavingCustomer">
        <ValidationObserver ref="createWaitingCustomer" v-slot="{ invalid }">
          <GuestForm
            :guest="guest"
            :invalid="invalid"
            :isSavingCustomer="isSavingCustomer"
            @save-changes="save"
          />
        </ValidationObserver>
      </b-overlay>
    </div>
    <div v-else-if="isLoadingInitialData" class="text-center">
      <b-spinner/> <br> Cargando información
    </div>

  </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { showAlertMessage, sweetAlert } from '@/helpers/helpers'
import GuestForm from '@/modules/guestPager/components/GuestForm'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  async created(){
    await this.init()
  },
  components: {
    GuestForm,
  },
  data() {
    return {
      isSavingCustomer:false,
      isLoadingInitialData: false,
      guest: {
        name: null,
        lastname: null,
        room: null,
        pax: null,
        lada: null,
        phone: null,
        consumptionCenter: this.currentConsumptionCenter || null,
        notes: null,
        language: null
      },
    }
  },
  computed:{
    ...mapState('guestPager', ['currentConsumptionCenter', 'consumptionCenters', 'countryCodes']),
    ...mapState('auth', ['user']),
    ...mapState('start', ['languages', 'hotels']),
  },
  methods: {
    ...mapActions('guestPager', ['createGuestOnHold', 'getInitialContentConsuptionsCenters']),
    async init(){
      this.isLoadingInitialData = true
      await this.getInitialContentConsuptionsCenters({
        hotels: !this.hotels.length,
        languages: !this.languages.length,
        countryCodes: !this.countryCodes.length,
      })
      this.guest = {
        name: null,
        lastname: null,
        room: null,
        pax: null,
        lada: null,
        phone: null,
        consumptionCenter: null,
        notes: null,
        language: null
      }
      this.isLoadingInitialData = false
    },
    async save(payload){
      this.isSavingCustomer = true
      const data = {
        idUser: this.user.idUser,
        guestName: payload.name,
        guestLastname: payload.lastname,
        consumptionCenter: payload.consumptionCenter,
        pax: payload.pax,
        room: payload.room,
        phone: payload.phone,
        lada: payload.lada?.code || null,
        notes: payload.notes,
        language: payload.language
      }
      const response = await this.createGuestOnHold(data)
      const { status, message, showMessage } = response
      if (status){
        showAlertMessage('Ok', 'InfoIcon', 'Cliente añadido a la lista de espera', 'success', 4000)
        this.isSavingCustomer = false
        this.$router.push({name: 'waiting-list'})
      } else {
        if (showMessage) await this.confirmSaving(message)
      }

      this.isSavingCustomer = false
    },
    async confirmSaving(message){
      const h = this.$createElement
      // Using HTML string
      const titleVNode = h('div', { domProps: { innerHTML: `¡No se pudo crear registro!`} })
      // More complex structure

      const messageVNode =
      h('div', { class: ['foobar'] }, [
        h('p', { class: ['text-center mb-1'] }, [`${message}`,]),
      ])

      // We must pass the generated VNodes as arrays
      const modal = await this.$bvModal.msgBoxOk([messageVNode], {
        title: [titleVNode],
        buttonSize: 'sm',
        centered: true,
      })

      return modal
    },
  }
}
</script>

<style>

</style>